import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import InlineCta, { InlineCtaProps } from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ErrorLinksProps {
  className?: string
  title?: RichTextBlocks
  links?: InlineCtaProps[]
}

function ErrorLinks({ className, title, links }: ErrorLinksProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title16_18Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  return (
    <div className={cx(css.ErrorLinks, className, gridStyle)}>
      <RichText className={cx(css.title, titleStyle)} render={title} />
      <div className={css.links}>
        {links?.map((link, index) => (
          <InlineCta
            className={css.link}
            textPreset={GlobalTextPreset.Cta12Haffer}
            theme={GlobalThemeColors.Black}
            textStyling={GlobalTextStyling.UpperCase}
            key={`link_${index}`}
            {...link}
          />
        )) ?? null}
      </div>
    </div>
  )
}

ErrorLinks.defaultProps = {}

export default ErrorLinks
